import jwtDecode from 'jwt-decode';
import config from '@ease/config/site.config'
import axios from 'axios';

class AuthHelper {
    login = async userInfo => {
        if (!userInfo.username || !userInfo.password) {
            return { error: 'please fill in the input' };
        }
        const data = {
            login: userInfo.username,
            password: userInfo.password
        };

        var promise = new Promise((resole, reject) => {
            axios.post(`${config.apiUserUrl}/v4/auth/login`, data)
                .then(value => {
                    const valid = this.checkExpirity(value.data.accessToken);
                    if(valid) {
                        resole(value.data);
                    } else {
                        reject({message:'expired token'});
                    }
                }).catch((er) => {
                if(er.response){
                    reject(er.response.data)
                }
                else
                    reject(null);
            });
        });

        return promise;
    };

    register = async userInfo => {
        if (!userInfo.email || !userInfo.password) {
            return { error: 'please fill in the input' };
        }

        var promise = new Promise((resole, reject) => {
            axios.post(`${config.apiUserUrl}/v4/auth/sign-up`, userInfo)
                .then(value => {
                    resole(value.data);
                }).catch((er) => {
                if(er.response){
                    reject(er.response.data)
                }
                else
                    reject(null);
            });
        });

        return promise;
    };

    checkExpirity = token => {
        if (!token) {
            return false;
        }
        try {
            const profile = jwtDecode(token);
            const expiredAt = profile.expiredAt || profile.exp * 1000;
            return expiredAt > new Date().getTime();
        }
        catch (e) {
            console.log(e);

            return false;
        }
    };
}
export default new AuthHelper();
