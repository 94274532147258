import React from 'react';
import {ConfigProvider} from 'antd';
import {IntlProvider} from 'react-intl';
import AppLocale from '@ease/config/translation';

export default function AppProvider({ children }) {
  const currentAppLocale = AppLocale['fr'];
  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={'fr'}
        messages={currentAppLocale.messages}
      >
          {children}
      </IntlProvider>
    </ConfigProvider>
  );
}
