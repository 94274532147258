import {all, put, takeEvery} from 'redux-saga/effects';
import actions from './actions';

export function* loadBuildings() {
    const buildings = yield fetchAsync(() => {
        return [{
            id: 1, name: 'febe', 'description': 'febe description', status: 'published',
            'city': 'douala', 'country': 'country', 'street': 'mendong', paymentRules: ['cash', 'wired']
        }];
    });

    yield put({
        type: actions.UPDATE_BUILDING,
        buildings,
    });
}

async function fetchAsync(func) {
    return await func();
}

export function* updateBuildingSaga({buildings, building}) {
    // yield // TODO update the server
    yield put({
        type: actions.UPDATE_BUILDING,
        buildings,
        building,
    });
}

export default function* rootSaga() {
    yield all([
        yield takeEvery(actions.GET_BUILDINGS, loadBuildings),
        yield takeEvery(actions.UPDATE_BUILDING_SAGA, updateBuildingSaga),
    ]);
}
