import { all } from 'redux-saga/effects';
import authSagas from '@ease/redux/auth/saga';

import buildingSaga from '@ease/redux/building/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    buildingSaga(),
  ]);
}

