import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken } from '@ease/lib/helpers/utility';
import actions from './actions';
import {updateBuildingSaga} from "../building/saga";

const history = createBrowserHistory();
const fakeApiCall = true; // auth0 or express JWT

// export function* loginRequest() {
//   yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
//     const { token } = payload;
//     const { user } = payload;
//
//     if (token) {
//       yield put({
//         type: actions.LOGIN_SUCCESS,
//         token: token,
//         profile: user.firstName,
//       });
//     } else {
//       if (fakeApiCall) {
//         yield put({
//           type: actions.LOGIN_SUCCESS,
//           token: 'secret token',
//           profile: 'Profile',
//         });
//       } else {
//         yield put({ type: actions.LOGIN_ERROR });
//       }
//     }
//   });
// }

export function* loginSuccess(data) {
  yield localStorage.setItem('id_token', data.payload.token);
  yield localStorage.setItem('user', data.payload.user);
}

// export function* loginError() {
//   yield takeEvery(actions.LOGIN_ERROR, function*() {});
// }

export function* logout() {
  yield clearToken();
  history.push('/');
}
export function* checkAuthorization() {
  const token = getToken().get('idToken');
  const user = getToken().get('user');
  if (token) {
    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: {
        token,
        profile: 'Profile',
        user: user
      }
    });
  }
}
export default function* rootSaga() {
  yield all([
    // fork(checkAuthorization),
    // fork(loginRequest),
    // fork(loginSuccess),
    // fork(loginError),
    // fork(logout),
    yield takeEvery(actions.LOGIN_SUCCESS, loginSuccess),
    yield takeEvery(actions.LOGOUT, logout),
    yield takeEvery(actions.CHECK_AUTHORIZATION, checkAuthorization),


  ]);
}
