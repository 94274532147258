import { combineReducers } from 'redux';
import App from '@ease/redux/app/reducer';
import Auth from '@ease/redux/auth/reducer';
import Building from '@ease/redux/building/reducer';

export default combineReducers({
  Auth,
  App,
  Building
});
