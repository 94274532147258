import clone from 'clone';
import actions from './actions';

const initState = {
    buildings: [],
    initialBuildings: false,
    currentBuilding: {},
    editableBuilding: {}
};

export default function cardReducer(state = initState, {type, ...action}) {
    switch (type) {
        case actions.UPDATE_BUILDING: {
            const currentBuilding = action.building
                ? action.building
                : state.currentBuilding;
            return {
                ...state,
                buildings: action.buildings,
                currentBuilding: clone(currentBuilding),
                initialBuildings: true,
            };
        }
        case actions.SELECT_BUILDING: {
            const buildings = state.buildings;
            const index = buildings.map(b => b.id).indexOf(action.id);
            const currentBuilding = buildings[index];
            return {
                ...state,
                currentBuilding,
            };
        }
        case actions.TOGGLE_VIEW:
            return {
                ...state,
                editableBuilding: clone(state.currentBuilding),
            };
        case actions.UPDATE_EDIT_BUILDING:
            return {
                ...state,
                editableBuilding: clone(action.building),
            };
        default:
            return state;
    }
}
