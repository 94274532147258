export default {
  siteName: "Ease",
  siteIcon: "ion-flash",
  footerText: `Ease Travel Services @ ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  apiUrl: process.env.REACT_APP_APARTMENT_SERVICE,
  apiHotelUrl: process.env.REACT_APP_HOTEL_SERVICE,
  apiTravelUrl: process.env.REACT_APP_TRAVEL_SERVICE,
  apiTravelUrlV2: process.env.REACT_APP_TRAVEL_SERVICE_V2,
  apiCarUrl: process.env.REACT_APP_CAR_SERVICE,
  apiUserUrl: process.env.REACT_APP_USER_SERVICE,
  apiCommonUrl: process.env.REACT_APP_COMMON_SERVICE,
  apiResaUrl: process.env.REACT_APP_RESA_SERVICE,
  apiPaymentUrl: process.env.REACT_APP_PAYMENT_SERVICE,
  cmUrl: process.env.REACT_APP_CM_URL,
  apiVisaUrl: process.env.REACT_APP_VISA_SERVICE,
  apiFlightUrl: process.env.REACT_APP_FLIGHT_SERVICE,

  google: {
    analyticsKey: "UA-xxxxxxxxx-1",
  },
  dashboard: "/dashboard",
};
