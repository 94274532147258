import { createGlobalStyle } from 'styled-components';

const borderColor = '#efefef';

const defaultBorder = `border: 2px solid ${borderColor} !important;border-radius: 6px !important;`

const easeInput = `
    height: 40px !important;
    padding: 4px 11px !important;
    ${defaultBorder}
    box-shadow: none !important;`;

const bgDark = "#001340";
const bgPrimary = "#2C6EFF";
const bgWarning = "#F1B44C2D";
const bgSuccess = "#34C38F2D";
const txtWarning = "#F1B44C";
const txtError = "#F46A6A";
const txtSuccess = "#34C38F";

const EaseStyles = createGlobalStyle`
    .ease-card{
        border-radius: 10px !important;
        background-color: white;
        box-shadow: 0px 10px 20px #12263F08;
        border: none !important;
        width: 100%;

        .ant-card-body{
            padding: 15px !important;
        }
        &.nopadding {
            .ant-card-body{
                padding: 0px !important;
            }
        }
        .stat{
            display: flex;
            align-items: center;
            
            .stat-content{
                display: flex;
                flex: 1;
                flex-direction: column;

                span{
                    font-size:13px;
                    font-weight: 400;
                }
                h3{
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: 3px;
                }
            }
            .stat-icon{
                width: 45px;
                height: 45px;
                background: ${bgPrimary};
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 20px;
            }
        }
    }
    .mt-1{
        margin-top:10px !important;
    }
    .p-1{
        padding:10px !important;
    }
    .mb-1{
        margin-bottom:10px !important;
    }
    .mb-5px{
        margin-bottom:5px !important;
    }
    .ml-1{
        margin-left:10px !important;
    }
    .mr-1{
        margin-right:10px !important;
    }
    .ant-select-selection-item{
        color:black !important;
    }
    .calendar-nav{
        padding: 5px 0 0 0;
        cursor: pointer;
        font-size: 18px;
        margin-right: 2px;
    }
    .font-12{
        font-size:12px !important;
    }
    .font-13{
        font-size:13px !important;
    }
    .vertical-center{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .isoBoxWrapper{
        border-radius: 8px;
    }
    .select-error{
        .ant-select-selector{
            background: #F46A6A2D !important;
            border:none !important;
        }
        .ant-select-selection-item{
            color: ${txtError} !important;
            font-weight:500 !important;
        }
    }
    .select-success{
        .ant-select-selector{
            background: #34C38F2D !important;
            border:none !important;
        }
        .ant-select-selection-item{
            color: #34C38F !important;
            font-weight:500 !important;
        }
    }
    .select-warning{
        .ant-select-selector{
            background: ${bgWarning} !important;
            border:none !important;
        }
        .ant-select-selection-item{
            color: ${txtWarning} !important;
            font-weight:500 !important;
        }
    }
    
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            ${easeInput};
        }
        .ant-select-single.select-warning:not(.ant-select-customize-input) .ant-select-selector,.ant-select-single.select-error:not(.ant-select-customize-input) .ant-select-selector,.ant-select-single.select-success:not(.ant-select-customize-input) .ant-select-selector{
            border:none !important;
        }
        .ant-input-affix-wrapper{
            ${easeInput};
            border:none !important;
            .ant-input{
                background: transparent !important;
            }
        }
        .input-bg .input.ant-input{
            height:auto !important;
            border:none !important;
        }
        .ant-input-affix-wrapper > input.ant-input{
            height:auto !important;
            border:none !important;
        }
        .ant-input.ease-input, input.ant-input{
            ${easeInput};
        }
        .ant-select-arrow{
            color: black !important;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
            font-size:12px;
        }
        .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input{
            padding-top:5px;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
            color: #00000099;
        }
        textarea.ant-input{
            ${defaultBorder};
        }
    
    .customPanel {
        .ant-collapse-header{
            height:0;
            padding:0 !important;
        }
        .ant-collapse-content-box{
            padding:0 !important;
        }
    }
    .hideFilter{
        cursor:pointer;
        color:black;
        border-bottom: 2px solid ${borderColor};
        font-size: 13px;
        position: absolute;
        bottom:auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .input-border{
        border: 2px solid ${borderColor} !important;
    }
    .input-bg{
        background: #f6f6f6 !important;
    }
    .ant-picker{
        ${easeInput};
    }
    .custom-radio{
        .ant-radio-inner{
            border-width: 2px;
            border-radius: 3px;
        }
        .ant-radio-inner:after{
            top: 50%;
            left: 50%;
            transform: scale(1) translate(-50%, -50%) !important;
        }
        .ant-radio-wrapper{
            display: flex !important;
            font-size: 11px !important;
            color: #00000099;
        }
    }
    .label-title{
        color: #00000099;
        margin-bottom:5px;
        display: block;
        font-size: 13px;
    }
    .customTable{
        td, th{
            font-size:12px
        }
        tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
            background: white;
        }
        .expandable-box{
            ${defaultBorder};
            border-radius: 8px !important;
        }
    }
    .custom-button{
        ${easeInput};
        padding: 4px 20px !important;
        font-weight: 500 !important;
    }
    .custom-button.ant-btn-primary{
        background: ${bgPrimary} !important;
        background-color: ${bgPrimary} !important;
        border-color: ${bgPrimary} !important;
    }
    .custom-button.ant-btn-danger{
        background: #ff4d4f !important;
        background-color: #ff4d4f !important;
        border-color: #ff4d4f !important;
    }
    .custom-button.ant-btn-dark{
        background: ${bgDark} !important;
        color:white !important;
        border: none !important;
    }
    .custom-button.ant-btn-light{
        background: #F6F6F6 !important;
        background-color: #F6F6F6 !important;
        border-color: #F6F6F6 !important;
    }
    .custom-button.ant-btn-sm{
        height: 30px !important;
        padding: 6px 10px !important;
        font-size: 12px !important;
    }
    .btn-search-link button{
        background: ${bgPrimary} !important;
        background-color: ${bgPrimary} !important;
        border-color: ${bgPrimary} !important;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;   
    }
    .btn-search-link input{
        background: #E0E9FD !important;
        background-color: #E0E9FD !important;
        border-color: #E0E9FD !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .bg-dark{
        background:${bgDark} !important;
        color:white;
    }
    .btn-dark{
        background:${bgDark} !important;
        color:white !important;
        border:none !important;
        border-radius:5px !important;
    }
    .icon-btn.ant-btn-sm{
        padding-top:3px !important;
    }
    .btn-light{
        background: #F6F6F6 !important;
        border: none !important;
        border-radius: 6px !important;
        height: 32px !important;
        padding: 4px 12px !important;
        font-size: 12px !important;
    }
    .bg-primary-soft{
        padding: 3px 7px 4px 7px;
        border-radius:5px;
        background:#E0E9FD; 
    }
    .bg-primary{
        background: ${bgPrimary} !important;
    }
    .inline-block{
        display:inline-block;
    }
    .text-primary{
        color: ${bgPrimary} !important;
    }
    .text-muted{
        color: #00000099;
    }
    .text-warning{
        color: ${txtWarning} !important;
    }
    .text-error{
        color: ${txtError} !important;
    }
    .text-success{
        color: ${txtSuccess} !important;
    }
    .text-dark{
        color: ${bgDark} !important;
    }
    .text-black{
        color: #000000 !important;
    }
    .text-medium{
        font-weight: 500 !important;
    }
    .text-semi-medium{
        font-weight: 400 !important;
    }
    .text-underline{
        text-decoration:underline; 
    }
    .text-normal{
        font-weight: 300 !important;
    }
    .text-bold{
        font-weight: 600 !important;
    }
    .text-bolder{
        font-weight: 700 !important;
    }
    .ant-tag{
        border: none !important;
        padding: 2px 8px !important;
    }
    .ant-tag-default{
        background: #0000000D !important;
        color: #0000004D !important;
    }
    .ant-tag-success{
        background: #34C38F2D !important;
        color: #34C38F !important;
    }
    .ant-tag-error{
        background: #F46A6A2D !important;
        color: #F46A6A !important;
    }
    .ant-tag-warning{
        background: #F1B44C2D !important;
        color: #F1B44C !important;
    }
    .ant-modal-content{
        border-radius:10px !important;
    }
    .ant-modal-header{
        border-top-left-radius:10px !important;
        border-top-right-radius:10px !important;
    }
    .w100 {
        width: 100%;
    }
    .customSlider{
        .ant-slider-track{
            background-color: #03143d !important;
        }
        .ant-slider-handle {
            width: 20px !important;
            height: 20px !important;
            margin-top: -7px !important;
            background-color: #03143d !important;
            border: solid 2px #03143d !important;
        }
        .ant-slider-rail {
            background-color: #cecece;
        }
    }
    .flex {
        display: flex;
    }
    .justbtw {
        justify-content: space-between;
    }
    .bottomBar{
        position: fixed;
        left: 80px;
        bottom: 0;
        right: 0;
        height: 80px;
        background: white;
        box-shadow: 0px -6px 20px #12263F08;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;
    }
    .bottomDrawer{
        left: 80px !important;
        width: calc(100% - 80px) !important;
    }
    .side-open .bottomBar{
        left: 240px;
    }
    .no-border{
        border:none !important;
    }
    .subheader{
        width: 100%;
        height: 65px;
        background: white;
        display: flex;
        align-items: center;
        padding-left: 20px;
        box-shadow: 0px 10px 20px #12263F08;
        position: relative;
        
        span{
            font-weight: 500;
        }
    }
    
    .subheader .close-button, .ant-drawer-close .close-button{
        width: 30px;
        height: 30px;
        font-size: 18px;
        position: absolute;
        right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-color: ${bgDark} !important;
    }
    
    .circle{
        ${defaultBorder};
        border-radius: 50% !important;
    }
    .u-mar-bottom-s {
        margin-bottom: 15px !important;
    }
    .ant-select-selector{
        ${defaultBorder};
    }
    .u-mar-top-s {
        margin-top: 15px;
    }
    
    .u-mar-left-s {
        margin-right: 15px;
    }
    .list-menu{
        cursor:pointer;
        padding:12px 20px;
        
        h3{
            font-weight: 400;
            font-size: 16px;
            color: #001340;    
        }
        &.active{
            background: #E0E9FD;
            &:first-child{
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            &:last-child{
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            h3{
                font-weight: 500;
            }   
        }
    }
    .badge{
        padding: 3px 7px;
        margin-right: 3px;
        margin-bottom: 3px;
        border-radius: 3px;
        font-size: 12px;
        display: inline-block;
    }
    .badge.primary{
        background: ${bgPrimary} !important;
        color: white;
    }
    .badge.dark{
        background: ${bgDark} !important;
        color: white;
    }
    .custom-check-group{
        .ant-checkbox-group-item {
            width: 30%;
            white-space: nowrap;
            font-size: 13px !important;
            opacity: 0.8;
        }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${bgDark} !important;
        border-color: ${bgDark} !important;
    }
    .ant-checkbox-checked::after {
        border: 1px solid ${bgDark} !important;
    }
    .text-first{
        max-width: 80%;
        height: 1.5em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
    }
    .saving-detail-item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 7px;
    }
    .saving-detail-item .item-name{
        opacity:0.7;
    }
    .saving-detail-item .item-value{
        font-weight: 600;
        font-size: 1.1em;
    }
    .saving-detail-item .item-value .ant-tag{
        margin-right: 0;
    }
    .payment-item{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }
    .payment-item .content{
        flex: 1;
        margin-left: 10px;
    }
    .payment-item .content .title{
        font-weight: 600;
    }
    .payment-item .content .title .price{
        float:right;
    }
    .payment-item .circle{
        width: 40px;
        height: 40px;
        color: ${txtSuccess} !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${bgSuccess};
        font-size: 20px;
    }
    .saving-detail-item-pg .ant-progress-bg{
        background-color: ${txtSuccess} !important;
    }
    .ant-drawer-bottom{
        .ant-drawer-content-wrapper, .ant-drawer-content, .ant-drawer-wrapper-body{
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
    }
    .list-saving-plan{
        display:flex;
        
        .saving-plan{
            border: 2px solid ${borderColor};
            display: flex;
            flex: 1;
            padding: 8px 12px;
            border-radius: 5px;
            justify-content: space-between;
            cursor: pointer;
            transition: 0.3s all;
            
            .check{
                width: 20px;
                height: 20px;
                border: 2px solid ${borderColor};
                border-radius: 10px;
                transition: 0.3s all;
            }
            &.select{
                transition: 0.3s all;
                border-color: ${bgDark};
                .check{
                    background: ${bgDark};
                    border-color: #d4d8e3;
                    border-width: 5px;
                    transition: 0.3s all;
                }
            }
        }
    } 
    .list-child {
        display: flex;
        flex-wrap: wrap;
        .ant-row{
            margin-right: 20px;
        }
    }
    @media only screen and (min-width: 1025px) {
        .bottomDrawer{
            left: 240px !important;
            width: calc(100% - 240px) !important;
        }
    }
    @media only screen and (max-width: 600px) {
        .bottomBar{
            left: 0;
        }
        .bottomDrawer{
            left: 0px !important;
            width: calc(100% - 0px) !important;
        }
    }
`;

export const easeBorder = defaultBorder;

export default EaseStyles;
