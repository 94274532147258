const actions = {
    GET_BUILDINGS: 'GET_BUILDINGS',
    UPDATE_BUILDING: 'UPDATE_BUILDING',
    UPDATE_BUILDING_SAGA: 'UPDATE_BUILDING_SAGA',
    TOGGLE_VIEW: 'BUILDING_TOGGLE_VIEW',
    SELECT_BUILDING: 'SELECT_BUILDING',
    UPDATE_EDIT_BUILDING: 'UPDATE_EDIT_BUILDING',

    loadData: () => ({type: actions.GET_BUILDINGS}),
    deleteBuilding: selected => {
        return (dispatch, getState) => {
            const buildings = getState().Buildings.buildings;
            const newBuildings = [];
            buildings.forEach(b => {
                const selectedIndex = selected.indexOf(b.id);
                if (selectedIndex === -1) {
                    newBuildings.push(b);
                }
            });
            dispatch({
                type: actions.UPDATE_BUILDING_SAGA,
                buildings: newBuildings,
            });
        };
    },
    updateBuilding: building => {
        return (dispatch, getState) => {
            const buildings = getState().Buildings.buildings;
            const index = buildings.map(b => b.id).indexOf(building.id);
            if (index === -1) {
                buildings.push(building);
            } else {
                buildings[index] = building;
            }
            dispatch({
                type: actions.UPDATE_BUILDING_SAGA,
                buildings,
                building,
            });
        }
    },
    toggleView: view => ({type: actions.TOGGLE_VIEW, view}),
    selectBuilding: id => ({type: actions.SELECT_BUILDING, id}),
    editBuilding: building => ({type: actions.UPDATE_EDIT_BUILDING, building}),
};
export default actions;
